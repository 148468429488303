import React , { useState  } from 'react';
/* global BigInt */
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import Countdown from "react-countdown";
import Footer from '../components/footer/Footer';
import useEth from '../contexts/EthContext/useEth'
import img1 from '../assets/images/background/img-login.jpg'
import img2 from '../assets/images/mika-nft.png'
// import useEth from "../contexts/EthContext/useEth";
// import { EthProvider } from "../contexts/EthContext";
import { toast, ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import icon from '../assets/images/icon/uniswap.svg'
import MyCollection from '../components/layouts/mint/MyCollectionItems';
import dataPopularCollection from '../assets/fake-data/dataPopularCollection';
import Spinner from 'react-bootstrap/Spinner';

import useWindowSize from 'react-use/lib/useWindowSize'

import Confetti from 'react-confetti';

const Mint = () => {
  const { state: { web3, artifact, artifact2, contract, contract2, accounts, balances, rates, openTime, mikaAllowance,merkleRoots,tokensOfOwner,tokenUriDetails } } = useEth();
  const [qty, setQty] = useState(1);

  const [frenListMint, setFrenListMint] = useState(false);
  const [giftListMint, setGiftListMint] = useState(false);
 const { width, height } = useWindowSize();
  const [total, setTotal] = useState(0);
  const [inTXflow, setinTexFlow] = useState(false);
  const [allTokensOfOwner, setNeTokensOfOwner] = useState(tokensOfOwner);
  const [transactions, setTransactions] = useState([]);
  const [isConfetti, setIsConfetti] = useState(false)
  const toastOptions = {
      type: toast.TYPE.INFO,
      hideProgressBar: true,
      isLoading: true,
      position: toast.POSITION.TOP_LEFT,
      progressClassName: 'success-progress-bar'
  }

  const toastId = React.useRef(null);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return ""
    } else {
      // Render a countdown
      return <span>{days} Days :{hours} Hrs :{minutes} Mins :{seconds} Seconds</span>;
    }
  };

const notEnoughMika = ()=>{
  console.log(balances.mika,(qty*(frenListMint?rates.fren:rates.main)),balances.mika<(qty*(frenListMint?rates.fren:rates.main)))
  return web3.utils.toWei(balances.mika)<(qty*(frenListMint?rates.fren:rates.main));
}

  const approveTransaction = async ()=>{
    if(giftListMint){
      mintTransaction()
      toastId.current = toast.loading(`Initiating Gift Mint ${(web3.utils.fromWei(frenListMint?rates.fren:rates.main,'ether')*qty)}`,{
      ...toastOptions,
      toastId:'approval'
      });
      return;
    }
    setinTexFlow(true);
    if(!giftListMint && notEnoughMika() ){
      toast.error('Not Enough Mika to complete this mint');
      setinTexFlow(false);
      return;
    }
    console.log('currentMikaAllowance is '+mikaAllowance);
    const total = BigInt(qty*(frenListMint?rates.fren:rates.main))
    console.log(qty, rates.main, total);
    toastId.current = toast.loading(`Initiating Approval ${(web3.utils.fromWei(frenListMint?rates.fren:rates.main,'ether')*qty)}`,{
    ...toastOptions,
    toastId:'approval'
    });
    console.log(qty*rates.main);
    if(mikaAllowance<(qty*rates.main)){

      try{
        await contract.methods.approve(contract2._address,total).send({from:accounts[0]})
         .on('transactionHash', (receipt) => {
           console.log({receipt})
           setTransactions([...transactions,{type:"Approved Mika Spending", txRef:receipt}])
         }).finally(async()=>{
            toast.update(toastId.current, { ...toastOptions, render: "Approved, queing mint transaction", type: toast.TYPE.INFO,transition: Flip});

             mintTransaction();

         });

      } catch(err){
        if(err.code === 4001){
          toast.update(toastId.current, { ...toastOptions, render: "User Aborted TX", loading:false, autoClose:3000, type: toast.TYPE.ERROR,transition: Flip, isLoading:false});
          setinTexFlow(false);
          return;
        }else {

        console.log(err)
        toast.update(toastId.current, { ...toastOptions, render: "Unable to Allow Token Spen", loading:false, autoClose:3000, type: toast.TYPE.ERROR,transition: Flip, isLoading:false});
        setinTexFlow(false);
        return;
        }
      }


    } else {
      mintTransaction();
    }
  }
const setOpen = async ()=>{
  await contract2.methods.setMainMintStart(Math.floor(Date.now()/1000)).send({from:accounts[0]});
}
const withdrawMika = async ()=>{
  await contract2.methods.withdrawMika().send({from:accounts[0]});
}
const setHiddenMetadataUri = async ()=>{
  await contract2.methods.setHiddenMetadataUri('https://i0.wp.com/jetsettimes.com/wp-content/uploads/2021/08/Hangaku-Gozen.jpg?fit=1125%2C1380&ssl=1').send({from:accounts[0]});
}
const updateTokensOfOwner = async()=>{
  const latestTokensOfOwner = await contract2.methods.tokensOfOwner(accounts[0]).call();
  console.log({latestTokensOfOwner})
  setNeTokensOfOwner(latestTokensOfOwner);
}
const updateMerkleRoots = async ()=>{
  await contract2.methods.setFrenListMerkleRoot('0x'+merkleRoots.fren.localRoot).send({from:accounts[0]});
  await contract2.methods.setGiftListMerkleRoot('0x'+merkleRoots.gift.localRoot).send({from:accounts[0]});
}

  const mintTransaction = async ()=>{

    console.log('currentMikaAllowance is '+mikaAllowance);
    const total = giftListMint?'0':BigInt(qty*(frenListMint?rates.fren:rates.main))
    console.log(qty, rates.main, total);


      try{
        const args = [];

        if(giftListMint){
          args.push(merkleRoots.gift.proof)
        } else if(frenListMint){
          args.push(merkleRoots.fren.proof)
        }
        args.push(qty)
        toast.update(toastId.current, { ...toastOptions, render: `Minting ${qty}`,type: toast.TYPE.SUCCESS,transition: Flip});
        await contract2.methods[giftListMint?'giftListMint':frenListMint?'frenListMint':'mint'](...args).send({from:accounts[0]})
         .on('transactionHash', (receipt) => {
           console.log({receipt})
           setTransactions([...transactions,{type:`Mika Genesis Mints (${qty}) `, txRef:receipt}])

         }).finally(async()=>{

           setIsConfetti(true);
            toast.update(toastId.current, { ...toastOptions, render: "Mint Complete!", loading:false, autoClose:1000,  type: toast.TYPE.SUCCESS, isLoading:false,transition: Flip});
           setTimeout(()=>{
             setIsConfetti(false)
           },5000)
           setinTexFlow(false);
          updateTokensOfOwner()

         });

      } catch(err){
        if(err.code === 4001){
          toast.update(toastId.current, { ...toastOptions, render: "User Aborted TX", loading:false, autoClose:1000,  type: toast.TYPE.ERROR, isLoading:false,transition: Flip});
          setinTexFlow(false);
          return;
        }else {

        console.log(err)
        toast.update(toastId.current, { ...toastOptions, render: "Unable to Mint", loading:false, autoClose:1000,  type: toast.TYPE.ERROR, isLoading:false,transition: Flip});
        setinTexFlow(false);
        return;
        }
      }
  }


  const onQtyChange = (e)=>{

        setQty(e.target.value >= maxMintItems()?maxMintItems():e.target.value)

    };
    const getQTY = ()=>{
      return qty;
    }
    const ethRate = ()=>{
        // console.log(
        //   web3.utils.fromWei(rates.main,'ether'),
        //   qty
        // )
        const rate =  web3.utils.fromWei(frenListMint? rates.fren :rates.main ,'ether')
        return Number(giftListMint?0:rate*Number(qty)).toLocaleString();
    }

    const burnRate = ()=>{
        const rate =  web3.utils.fromWei(frenListMint? rates.fren :rates.main,'ether')

        return Number(giftListMint?0:rate*Number(qty)*rates.burn/100).toLocaleString();
    }

    const maxMintItems = ()=>{
      if(giftListMint){
        return merkleRoots.gift.limit - merkleRoots.gift.minted;
      } else if(frenListMint){
        console.log(merkleRoots.fren.limit, merkleRoots.fren.minted);
        return merkleRoots.fren.limit - merkleRoots.fren.minted
      } else {
        return Math.min(tokenUriDetails.totalMints-tokenUriDetails.totalSupply,tokenUriDetails.maxMintAmountPerTx)
      }
    }

  return <div>
    <Header account={accounts?accounts[0]:''} balances={balances} />
    {isConfetti && <Confetti height={height} width={width} tweenDuration={5000} numberOfPieces={4500} recycle={false} />}

    <section className="tf-section login-page">
    {web3 && (
        <div className="container">

            <div className="row">
                <div className="col-md-12">
                    <div className="form-create-item-content">
                        <div className="form-create-item">
                            <div className="sc-heading">
                                <h3>Mint your Mikas</h3>
                                <p className="desc">LFG Minters... </p>
                                <p className="desc">Minted {tokenUriDetails.totalSupply} of {tokenUriDetails.totalMints} ({tokenUriDetails.totalMints-tokenUriDetails.totalSupply} Remaining) </p>

                            </div>

                            { (Number(tokenUriDetails.totalSupply) < Number(tokenUriDetails.totalMints)) && (
                            <div>
                            {inTXflow &&(

                              <div className="spinner">
                              </div>

                            )}
                            <form id="create-item-1" action="#" method="GET" acceptCharset="utf-8">

                                <div className="row">
                                <div className="col-md-6">
                                <input name="user" value={qty} type="number" step="1" min="1" max={maxMintItems()}
                                    required onChange={(e)=>{onQtyChange(e)}} />
                                </div>
                                <div className="col-md-6">

                                <h4>Mikaboshi Cost: {ethRate()}</h4>
                                <h4>Bonus Burn of {burnRate()}
                                <span>

                                {Array.apply(null, { length: qty }).map((e, i) => (
                                      <span key={i}>🔥</span>
                                ))}
                                </span></h4>
                                </div>
                                </div>
                                { (merkleRoots.fren.listed &&((merkleRoots.fren.limit - merkleRoots.fren.minted)>0) )  && (
                                <div className="input-group style-2 ">
                                    <div className="btn-check">
                                        <input disabled={giftListMint} type="checkbox" id="fren" name="fav_language" checked={frenListMint} onChange={(e)=>{setFrenListMint(!frenListMint)}} />
                                        <label htmlFor="fren">FrenList Mint ({merkleRoots.fren.limit - merkleRoots.fren.minted} left) </label>
                                    </div>
                                </div>
                                )}
                                { (merkleRoots.gift.listed && (((merkleRoots.gift.minted==0?merkleRoots.gift.defaultMax: merkleRoots.gift.limit) - merkleRoots.gift.minted)>0)) && (
                                <div className="input-group style-2 ">
                                    <div className="btn-check">
                                        <input disabled={frenListMint} type="checkbox" id="gift" name="fav_language" checked={giftListMint} onChange={(e)=>{setGiftListMint(!giftListMint)}} />
                                        <label htmlFor="gift">GiftList Mint ({merkleRoots.gift.minted==0?1:merkleRoots.gift.limit - merkleRoots.gift.minted} left)</label>
                                    </div>
                                </div>
                                )}



                            </form>

                          <button className="sc-button style letter style-2 block" onClick={()=>{approveTransaction(); return false;}}><span>Mint these mika bad boys</span> </button>

</div>
                         )}
                            {notEnoughMika() &&(
                              <div className="not-enough-mika">
                              <h4>Not enough Atsuma-Mikaboshi balance for this mint...</h4>
                              <p>Head on over to uniswap to load up on more Amatsu Mikaboshi now.<br/>
                              <a className="sc-button wallet style style-2 uniswap" rel="noreferrer" target="_blank" href="https://app.uniswap.org/#/swap"><img src={icon} alt="icon" /><span>Uniswap</span></a>
                              </p>
                              </div>
                            )}
                        </div>
{/*
                      <a href="#" className="sc-button style letter style-2" onClick={()=>{setOpen()}}><span>Set Open admin only</span> </a>
                     <a href="#" className="sc-button style letter style-2" onClick={()=>{withdrawMika()}}><span>Withdraw Mika</span> </a>
                        <a href="#" className="sc-button style letter style-2" onClick={()=>{updateMerkleRoots()}}><span>updateMerkleRoots</span> </a>
                        <a href="#" className="sc-button style letter style-2" onClick={()=>{setHiddenMetadataUri()}}><span>set hiddenMetadataUri</span> </a>
*/}
                        <div className="form-background">
                            <img src={img2} alt="Mikaboshi" />
                        </div>
                    </div>
                </div>
            </div>
            <div>
              {transactions.length > 0 && (

                <div className="row">
                    <div className="col-md-12">
                <h4>Transactions:</h4>
                <ul>
                 { transactions.map((tx, index) => (

                  <li key={index}>
                    <strong>{tx.type}</strong> &nbsp; <a target="_blank" href={`https://etherscan.io/tx/${tx.txRef}`}>{tx.txRef}</a>
                  </li>

                ))}
                </ul>
                </div>
                </div>
              )}
            </div>
            </div>
        )}
    </section>

    <MyCollection  data={allTokensOfOwner.length?[...allTokensOfOwner].reverse():[...tokensOfOwner].reverse()} tokenUriDetails={tokenUriDetails} />
    <ToastContainer />
    <Footer />
  </div>;
};

export default Mint;
