import { EthProvider } from "./contexts/EthContext";



import { Routes , Route } from 'react-router-dom'
import routes from './pages/router'

import "./App.css";

function App() {

  return (
    <div>
      {/*<div id="App" >
        <div className="container">

          <Demo />
          <hr />
          <Footer />
        </div>
      </div>
      */}
      <Routes >
          {
          routes.map((data,index) => (
              <Route exact={true} path={data.path} element={data.component} key={index} />
          ))
          }
    </Routes>
    </div>
  );
}

export default App;
