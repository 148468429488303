import React , { useRef , useState , useEffect } from 'react';
import TopBar from './TopBar';
import { Link , useLocation } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import logo from '../../assets/images/logo/logo_dark.png'
import logo2x from '../../assets/images/logo/logo_dark@2x.png'
import logolight from '../../assets/images/logo/logo.png'
import logolight2x from '../../assets/images/logo/logo@2x.png'
import menus from "../../pages/menu";
import DarkMode from "./DarkMode"

import  useENS  from "../../contexts/EthContext/useENS";

import icon from '../../assets/images/icon/connect-wallet.svg'

const Header = ({account, balances}) => {

    const { ensName, ensAvatar, loading } = useENS(account);
    const [show, setShow] = useState(false);
    // const [balance, setBalances] useState({
    //   eth:0,
    //   mika:0
    // })

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { pathname } = useLocation();
    const headerRef = useRef (null)
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });
    const isSticky = (e) => {
        const header = document.querySelector('.js-header');
        const scrollTop = window.scrollY;

        scrollTop >= 100 ? header.classList.add('is-fixed') : header.classList.remove('is-fixed');
        scrollTop >= 120 ? header.classList.add('is-small') : header.classList.remove('is-small');
    };

    const menuLeft = useRef(null)
    const btnToggle = useRef(null)

    const menuToggle = () => {
        menuLeft.current.classList.toggle('active');
        btnToggle.current.classList.toggle('active');
    }


    const [activeIndex, setActiveIndex] = useState(null);
    const handleOnClick = index => {
        setActiveIndex(index);
    };

    const formatNumber = (num)=> {
  return Number(num).toLocaleString('en-US', { maximumFractionDigits: 6 });
}



    return <div>

      {/*<TopBar />*/}
      <header id="header_main" className="header_1 js-header" ref={headerRef}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                      <div>
                        <div id="site-header-inner" className="flex flex-justify-between">
                        <div className="flex flex-justify-between">
                            <div id="site-logo" className="clearfix">
                                <div id="site-logo-inner">
                                    <Link to="/" rel="home" className="main-logo">
                                        <img id="logo_header" className='logo-dark' src={logo} srcSet={logo2x} alt="nft-gaming" />
                                        <img id="logo_header" className='logo-light' src={logolight} srcSet={logolight2x} alt="nft-gaming" />
                                    </Link>
                                </div>
                            </div>


                            <nav id="main-nav" className="main-nav" ref={menuLeft}>
                                <ul id="menu-primary-menu" className="menu">
                                    {
                                        menus.map((data,index) => (
                                            <li key={index} onClick={()=> handleOnClick(index)} className={`menu-item ${activeIndex === index ? 'active' : ''} ` }   >
                                                <Link to={data.links}>{data.name}</Link>
                                                {/*<ul className="sub-menu" >
                                                    {
                                                        data.namesub.map((submenu,index) => (
                                                            <li key={index} className={
                                                                pathname === submenu.links
                                                                    ? "menu-item current-item"
                                                                    : "menu-item"
                                                                }><Link to={submenu.links}>{submenu.sub}</Link></li>
                                                        ))
                                                    }
                                                </ul>
                                                */}
                                            </li>
                                        ))
                                    }
                                </ul>
                            </nav>
                            </div>


                              <div id="site-header-inner-right" className="flex">
                              { !account ? (
                              <div className="button-connect-wallet">
                                  <a href="#" className="sc-button wallet  style-2">
                                      <img src={icon} alt="icon" />
                                      Connect
                                  </a>
                              </div>
                            ):(
                              <div onClick={handleShow} className="button-connect-wallet">
                                  <a href="#" className="sc-button wallet  style-2">
                                      <img src={icon} alt="icon" />

                                      <span>{ensName ? ensName:account}</span>

                                  </a>
                              </div>
                            )}
                            <DarkMode />
                            <div className="mobile-button" ref={btnToggle} onClick={menuToggle}><span></span></div>
</div>
                            </div>
                        </div>
                            </div>
                </div>
            </div>
        </header>


     <Modal className="wallet-modal" show={show} onHide={handleClose}>
       <Modal.Header>
         <Modal.Title>Your Wallet Balances</Modal.Title>
       </Modal.Header>
       <Modal.Body>
       {balances && (
       <dl>
        <dt>Eth</dt>
        <dd>{formatNumber(balances.eth)}</dd>
        <dt>Amatsu-Mikaboshi</dt>
        <dd>{formatNumber(balances.mika)}</dd>
       </dl>
       )}
       </Modal.Body>
       <Modal.Footer>
         <Button variant="primary" onClick={handleClose}>
          Done!
         </Button>
       </Modal.Footer>
     </Modal>
  </div>;
};

export default Header;
