
import img1 from '../images/mika-nft.png'

const dataSlider = [
    {
        title: "It Memes Everything to Us.",
        description: "Welcome ot the world of Mikaboshi Nfts, a door opened into the possibilities for the mikaboshi ecosystem. This is an Independant Artists effort by Indelible and WhoisItNFT.",
        img: img1,
    },
    // {
    //     title: "Discover and collect your favorite digital NTFs",
    //     description: "Quis autem vel eum iure reprehenderit qui in ea voluptates esse quam nihil molestiae consequatur veillum",
    //     img: img1,
    // },

]

export default dataSlider;
