import { ethers } from "ethers";
import { useEffect, useState } from "react";

const useENS = (address: string) => {
  const [ensName, setENSName] = useState(null);
  const [ensAvatar, setENSAvatar] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const resolveENS = async () => {
      setLoading(true);
      if (address && ethers.utils.isAddress(address)) {
        try {
            const provider = new ethers.providers.InfuraProvider('homestead',{
                //const provider = new InfuraProvider('homestead',{
                    //etherscan: "F1I4M3HAI9KJQCTFZR8X37YI7N67GM6VKQ",
                    infura: "0cb58b812624401785797c057338fa2d"
                });
          let ensName = await provider.lookupAddress(address);
          const resolver = ensName ? await provider.getResolver(ensName) : null;
          let avatar = resolver ? await resolver.getAvatar() : null;
          setENSName(ensName);
          console.log({ensName})
          if(avatar){
            setENSAvatar(avatar.url);
          }
        } finally {
          setLoading(false);
        }
      }
    };
    resolveENS();
  }, [address]);

  return { ensName, ensAvatar, loading };
};

export default useENS;
