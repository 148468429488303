import React , { useState , useEffect } from 'react';
import { Link } from 'react-router-dom'
import img1 from '../../assets/images/post/post-recent-new-4.jpg'
import img2 from '../../assets/images/post/post-recent-new-5.jpg'

import logo from '../../assets/images/logo/logo_dark.png'
import logo2x from '../../assets/images/logo/logo_dark@2x.png'
import img3 from '../../assets/images/mika-nft.png'
const Footer = () => {
    const [dataSocial] = useState([

        {   link:'https://t.me/MikaboshiCommunity',
            icon: 'fab fa-telegram'
        }
    ])

    const [dataLinkMarket] = useState([
      {
          title: 'NFT Contract',
          link: 'https://etherscan.io/address/0x93040a61f848db31a886b38686deb7e0effd476d'
      },
        {
            title: 'Mikaboashi DAO',
            link: 'https://mikaboshidao.com/'
        },
        {
            title: 'Medium',
            link: 'https://medium.com/@amatsumikaboshieth/what-is-amatsu-mikaboshi-235f097fe550'
        }
    ])

    const [dataSupport] = useState([
        {
            title: 'Telegram Room',
            link: 'https://t.me/MikaboshiCommunity'
        },
        {
            title: 'Twitter Mikaboshi Info',
            link: 'https://twitter.com/Mikaboshi_Info'
        }
        ,
        {
            title: 'Twitter MikaboshiUltra',
            link: 'https://twitter.com/MikaboshiUltra'
        }
        ,
        {
            title: 'Twitter Carsonated',
            link: 'https://twitter.com/carsonated'
        }

    ])

    const [dataRecent] = useState([
        {
            img: img3,
            title: 'Mint opening soon...',
            time: '1 DEC 2022',
        }
    ])

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };

    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };

      window.addEventListener("scroll", toggleVisibility);

      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);
  return (
      <div>
            <footer id="footer" className="clearfix bg-style">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="widget widget-logo">
                                <div className="logo-footer" id="logo-footer">
                                    <Link to="/">
                                        <img id="logo_footer" src={logo} alt="nft-gaming"
                                            srcSet={logo2x} />
                                    </Link>
                                </div>
                                <p className="sub-widget-logo">
                                Welcome to the world of mikaboshi,</p>
                                <div className="widget-social">
                                    <ul>
                                        {
                                            dataSocial.map((item,index)=> (
                                                <li key={index}><a target="_blank" href={item.link}><i className={item.icon}></i></a></li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6 col-6">
                            <div className="widget widget-menu menu-marketplace">
                                <h5 className="title-widget">Marketplace</h5>
                                <ul>
                                    {
                                        dataLinkMarket.map((item,index)=> (
                                            <li key={index}><a target="_blank" href={item.link}>{item.title}</a></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="widget widget-menu menu-supports">
                                <h5 className="title-widget">Supports</h5>
                                <ul>
                                    {
                                        dataSupport.map((item,index)=> (
                                            <li key={index}><a target="_blank" href={item.link}>{item.title}</a></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget widget-post">
                                <h5 className="title-widget">News & Post</h5>
                                <ul className="post-new">
                                    {
                                        dataRecent.map((item,index)=> (
                                            <li key={index}>
                                                <div className="post-img">
                                                    <img src={item.img} alt="Minting Soon" />
                                                </div>
                                                <div className="post-content">
                                                    <h6 className="title">{item.title}</h6>

                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="bottom">
                <div className="container">
                    <div className="bottom-inner">
                        Rock out with your cock out.
                    </div>
                </div>
            </div>
            {
                isVisible &&
                <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            }
      </div>
  );
};

export default Footer;
