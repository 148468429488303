import React, { useReducer, useCallback, useEffect } from "react";
import Web3 from "web3";
import EthContext from "./EthContext";
import { reducer, actions, initialState } from "./state";
import {MerkleTree} from "merkletreejs"
import keccak256 from "keccak256"

import frenlist from "./../../assets/lists/frenlist.json"
import giftlist from "./../../assets/lists/giftlist.json"

let leaves = frenlist.map(addr => keccak256(addr))
let leavesGift = giftlist.map(addr => keccak256(addr))

// Create tree
let merkleTreeGift = new MerkleTree(leavesGift, keccak256, {sortPairs: true});
let merkleTree = new MerkleTree(leaves, keccak256, {sortPairs: true});

// Get root
let rootHashGift = merkleTreeGift.getRoot().toString('hex')
let rootHash = merkleTree.getRoot().toString('hex')


function EthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const init = useCallback(
    async (artifact,artifact2) => {
      if (artifact && artifact2) {
        // const network = "mainnet"
        // const web3 = new Web3(
        //   new Web3.providers.HttpProvider(
        //       `https://${network}.infura.io/v3/0cb58b812624401785797c057338fa2d`
        //   )
        // );

      var provider = 'https://mainnet.infura.io/v3/0df4d502b6c14ff8977fd62562299375';
      var web3Provider = new Web3.providers.HttpProvider(provider);
      //var web3 = new Web3(web3Provider);
      var web3 = new Web3(Web3.givenProvider)
      await web3.eth.getBlockNumber().then((result) => {
        console.log("Latest Ethereum Block is ",result);
      });
      console.log({web3_eth:web3.eth})

        const accounts = await web3.eth.requestAccounts();

        const networkID = await web3.eth.net.getId();
        console.log({accounts,networkID})
        const { abi } = artifact;
        const  abi2  = artifact2.abi;
        let address, contract;
        let address2, contract2;
        let balances;
        let mikaBalance;
        let ethBalance;
        let rates;
        let mintRate;
        let burnRate;
        let frenListMintRate;
        let openTime;
        let mikaAllowance = 0;

        let merkleRoots;
        let frenListMerkleRoot;
        let giftListMerkleRoot;
        let tokensOfOwner;
        let tokenUri;
        let revealing;
        let revealTo;
        let uriPrefix;
        let uriSuffix;
        let hiddenMetadataUri;

        let tokenUriDetails;
        let totalMints;
        let totalSupply;
        let frenListMintLimits;
        let giftListMintLimits;
        let giftListDefaultMax;
        let frenListMinted;
        let giftListMinted;
        let maxMintAmountPerTx;

        const leaf = keccak256(accounts[0]);
    		const proof = merkleTree.getHexProof(leaf);
    		const isFrenList = merkleTree.verify(proof, leaf, rootHash);


        const leafgift = keccak256(accounts[0]);
    		const proofgift = merkleTreeGift.getHexProof(leafgift);
    		const isGiftList = merkleTreeGift.verify(proofgift, leafgift, rootHashGift);



        try {
          address = '0xaE86f48c0B00F2a3eaeF4ba4c23d17368f0f63f4';
          contract = new web3.eth.Contract(abi, address);
          address2 = '0x93040a61f848db31a886b38686deb7e0effd476d';
          contract2 = new web3.eth.Contract(abi2, address2);
          mikaBalance = await  contract.methods.balanceOf(accounts[0]).call( { from: accounts[0] });
          ethBalance = await web3.eth.getBalance(accounts[0])

          mintRate = await  contract2.methods.mintRate().call();
          frenListMintRate = await  contract2.methods.frenListMintRate().call();

          openTime = await  contract2.methods.mainMintStart().call();

          burnRate = await  contract2.methods.burnPercentage().call();

          revealing = await  contract2.methods.revealing().call();
          revealTo = await  contract2.methods.revealTo().call();

          uriSuffix = await  contract2.methods.uriSuffix().call();
          uriPrefix = await  contract2.methods.uriPrefix().call();
          hiddenMetadataUri = await  contract2.methods.hiddenMetadataUri().call();
          totalMints = await  contract2.methods.totalMints().call();
          totalSupply = await  contract2.methods.totalSupply().call();
          // console.log(accounts[0],contract2._address);

          mikaAllowance = await contract.methods.allowance(accounts[0], contract2._address).call()
          console.log({mikaAllowance})
          console.log({burnRate})

          frenListMerkleRoot = await contract2.methods.frenListMerkleRoot().call()
          giftListMerkleRoot = await contract2.methods.giftListMerkleRoot().call()

          frenListMintLimits = await contract2.methods.frenListMintLimits().call()
          giftListMintLimits = await contract2.methods.giftListMintLimits(accounts[0]).call()
          giftListDefaultMax = await contract2.methods.giftListDefaultMax().call()

          frenListMinted= await contract2.methods.frenListMinted(accounts[0]).call()
          giftListMinted= await contract2.methods.giftListMinted(accounts[0]).call()

          tokensOfOwner = await contract2.methods.tokensOfOwner(accounts[0]).call()

          maxMintAmountPerTx = await contract2.methods.maxMintAmountPerTx().call()
          balances = {
            eth:web3.utils.fromWei(ethBalance,'ether').toLocaleString(),
            mika:web3.utils.fromWei(mikaBalance,'ether').toLocaleString(),
          }
          rates = {
            main:mintRate,
            fren:frenListMintRate,
            burn:burnRate
          }
          merkleRoots={
            fren:{localRoot:rootHash, root:frenListMerkleRoot,proof,listed:isFrenList,limit:frenListMintLimits, minted:frenListMinted},
            gift:{localRoot:rootHashGift, root:giftListMerkleRoot,proof:proofgift, listed:isGiftList,limit: giftListMintLimits?giftListMintLimits:giftListDefaultMax , defaultMax:giftListDefaultMax, minted:giftListMinted}
          }

          tokenUriDetails ={
            uriPrefix,
            uriSuffix,
            hiddenMetadataUri,
            revealing,
            revealTo,
            totalMints,
            totalSupply,
            arwaeveImagePrefix:'http://arweave.net/ZlsYjluyZ9f6UgH2Dn61g0OKebD_RjHGzB9AElI1n9g/',
            maxMintAmountPerTx
          }
          console.log({tokenUriDetails})

        } catch (err) {
          console.error(err);
        }
        console.log({tokensOfOwner})
        dispatch({
          type: actions.init,
          data: { artifact, artifact2, web3, accounts, networkID, contract,contract2, balances, rates, openTime, mikaAllowance, merkleRoots,tokensOfOwner, tokenUriDetails}
        });
      }
    }, []);

  useEffect(() => {
    const tryInit = async () => {
      try {
        const artifact = require("../../contracts/Amatsu.json");
        const artifact2 = require("../../contracts/PayWithIERC20.json");
        init(artifact,artifact2);
      } catch (err) {
        console.error(err);
      }
    };

    tryInit();
  }, [init]);

  useEffect(() => {
    const events = ["chainChanged", "accountsChanged"];
    const handleChange = () => {
      init(state.artifact,state.artifact2);
    };

    events.forEach(e => window.ethereum.on(e, handleChange));
    return () => {
      events.forEach(e => window.ethereum.removeListener(e, handleChange));
    };
  }, [init, state.artifact,state.artifact2]);

  return (
    <EthContext.Provider value={{
      state,
      dispatch
    }}>
      {children}
    </EthContext.Provider>
  );
}

export default EthProvider;
