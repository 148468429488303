const menus = [
    {
        id: 1,
        name: 'Home',
        namesub: [
        ],
        links: '/'
    },
    {
        id: 2,
        name: 'Mint',
        links:'/mint',
        namesub: []
    }

]

export default menus;
