const actions = {
  init: "INIT",
};

const initialState = {
  artifact: null,
  artifact2: null,
  web3: null,
  accounts: null,
  networkID: null,
  contract: null,
  contract2: null,
  balances:{eth:0, mika:0},
  rates:{main:0,fren:0, burn:0},
  openTime:null,
  mikaAllowance:0,
  merkleRoots:{fren:{localRoot:null,root:null,proof:null,listed:false},gift:{localRoot:null,root:null,proof:null,listed:false}},
  tokensOfOwner:[],
  tokenUriDetails:{
    uriPrefix:null,
    uriSuffix:null,
    hiddenMetadataUri:null,
    revealing:null,
    revealTo:null,
    totalMints:null,
    arwaeveImagePrefix:'https://arweave.net/ZlsYjluyZ9f6UgH2Dn61g0OKebD_RjHGzB9AElI1n9g/',
    maxMintAmountPerTx:null,
  }
};

const reducer = (state, action) => {
  const { type, data } = action;
  switch (type) {
    case actions.init:
      return { ...state, ...data };
    default:
      throw new Error("Undefined reducer action type");
  }
};

export {
  actions,
  initialState,
  reducer
};
